import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const StyledImage = styled(Plaatjie)`
  width: 850px;
  height: 100%;
  position: absolute !important;
  right: 50px;
  bottom: 0;

  & img {
    object-fit: contain !important;
  }

  @media screen and (max-width: 991px) {
    width: 500px;
    opacity: 0.1;
    bottom: 0px;
    right: -200px;
    z-index: -1;
  }

  @media screen and (max-width: 768px) {
    width: 400px;
  }

  @media screen and (max-width: 576px) {
    width: 350px;
    right: -70px;
    bottom: 80px;
  }
`

const Content = styled(ParseContent)`
  & h2 {
    font-size: ${(props) => props.theme.font.size[40]};
    line-height: 35px;
    padding-bottom: 30px;
    font-weight: ${(props) => props.theme.font.weight.s};
  }
`

interface TextWithBigIconProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_TextWithBigIcon
}

const TextWithBigIcon: React.FC<TextWithBigIconProps> = ({ fields }) => (
  <div className="container py-lg-5">
    <div className="row pt-lg-5 pt-4">
      <div className="col-lg-5 d-flex align-items-end">
        <StyledImage
          className="d-lg-block d-none"
          alt=""
          image={fields.image}
        />
      </div>
      <div className="col-lg-7">
        <Content content={fields.description} />
        <StyledImage
          className="d-lg-none d-block"
          alt=""
          image={fields.image}
        />
      </div>
    </div>
  </div>
)

export default TextWithBigIcon
